<template>
  <workbenchbox>
    <div class="dp-f page">
      <div class="mt-12">
        <div class="mainBg dp-fc c-fff">
          <div>
            <p class="fs-16 title">Operation assistant</p>
            <p class="fs-24">操作助手</p>
          </div>
        </div>
        <div class="mt-12 w-276 bg-fff list autobox2" v-loading="loading" :element-loading-text="config.loading.text"
        :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
          <div class="row b-b1 ta-c cu-p" v-for="(item,ind) in titleList" :key="ind" :class="[state.index==ind?'c-m':'']" @click=" select(ind)">
            {{item.title}}
          </div>
          <div class="w100 dp-f jc-c mt-20">
            <oabutton title="返回首页" CSStype=1 width=80 height=30 @buttonclick="router_push_name('operation_worktable')"></oabutton>
          </div>
        </div>
      </div>
      <div class="ml-12 bg-fff w100 mt-12 right" style="overflow:auto;">
        <openAPI ref="openAPIRef" v-model:index="state.index"></openAPI>
      </div>
    </div>
  </workbenchbox>
</template>

<script setup>
import { ref,unref, reactive } from "vue";
import { httpToken } from "@/utils/request";
import { router_push_name } from "@/utils/server/router.js"
import { useRoute } from "vue-router";
// import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
import workbenchbox from "@/components/layout/workbenchbox.vue";
import openAPI from "./components/topHelper/openAPI.vue";
const route=useRoute()
const openAPIRef=ref()
const loading = ref(false);//loading 显示
const titleList = ref([]);
const state=reactive({
  index:0,//当前选中下标
})
console.log('route11',route.params.id)
// http://116.63.252.12:9999/contentmanagement/selectOperateList 获取列表
// http://116.63.252.12:9999/contentmanagement/selectOperateListContent 查看详情
const select=(ind)=>{
  state.index=ind
  unref(openAPIRef).getDetailData(titleList.value[state.index].id)
}
// 获取列表数据
const getData=()=>{
  httpToken({
    method: "post",
    url: 'http://api.manage.yilanshuzi.com/backstage/contentmanagement/selectOperateList',
    // url: 'http://116.63.252.12:9999/backstage/contentmanagement/selectOperateList',
  }).then(res => {
    titleList.value=res.data
    if(route.params.id){
      for(let i=0;i<res.data.length;i++){
        if(res.data[i].id==route.params.id){
          state.index=i
        }
      }
    }
    console.log('titleList.value[state.index].id',state.index)
    unref(openAPIRef).getDetailData(titleList.value[state.index].id)
    loading.value = false;
  }).catch((err) => {
    loading.value = false;
  })
}
getData()
</script>

<style lang=scss scoped>
.mainBg {
  background-color: var(--active-theme);
  width: 276px;
  height: 136px;
  .title{
    text-transform:uppercase;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}
.list {
  height: calc(100vh - 258px)
}
.row {
  margin: 0 50px;
  padding: 19px 0;
}
.c-m {
  color: var(--active-theme);
}
.page {
  height: calc(100vh - 88px);
}
.right {
  height: calc(100vh - 110px);
}
</style>