<template>
  <div class="pt-44 pl-44 pr-44">
    <p class="title mb-20">{{state.data.title}}</p>
    <div class="body" v-html="state.data.content"></div>
  </div>
</template>

<script setup>
import { ref,reactive, defineProps, computed } from "vue";
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
const loading = ref(false);//loading 显示
const state=reactive({
  data:{},
})
const getDetailData=(id)=>{
  state.data={}
  queryResourceTable('http://api.manage.yilanshuzi.com/backstage/contentmanagement/selectOperateListContent', qs.stringify({
  // queryResourceTable('http://116.63.252.12:9999/backstage/contentmanagement/selectOperateListContent', qs.stringify({
    id: id,
  })).then((res) => {
    state.data=res.data
    loading.value = false;
  }).catch((err) => {
    if (err.message != 'stop')
      loading.value = false;
  })
}
defineExpose({
  getDetailData,
});
</script>
<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}

.body {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  div+div {
    margin-top: 20px;
  }
}
</style>